<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height:'88%'}"
      class="pt-2"
    >
      <v-col>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-row
            no-gutters
          >
            <v-col 
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center"
            >
              Transação:              
            </v-col>
            <v-col   
              sm="2"
              md="2"
              :cols="$vssWidth < 600 ? 3 : 8"
              class="pt-5 pr-2"
            >
              <v-text-field
                label=""
                value=""                
                reverse
                outlined
                dense
                flat        
                single-line      
                :style="{width:''}"
              />
            </v-col>
            <v-col  
              sm="6"
              md="5"
              :cols="$vssWidth < 600 ? 5 : 8"
              class="pt-5 pl-0"
            >
              <v-text-field
                label=""
                value=""
                outlined
                disabled
                filled
                dense
                flat        
                single-line
              />
            </v-col>
          </v-row>  
          <v-row no-gutters>
            <v-col
              sm="3"
              md="3"
              :cols="$vssWidth < 600 ? 4 : 8"
              class="d-flex align-center pb-5"
            >
              Pagamento:
            </v-col>
            <v-col
              sm="8"
              md="7"
              :cols="$vssWidth < 600 ? 8 : 8"
              class="pt-0"
            >
              <v-select
                dense
                outlined
                :items="['Dinheiro', 'Pix', 'Vale troca', 'Vale presente', 'Vale funcionário', ]"
              />
            </v-col>  
          </v-row>  
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <v-card-title                  
                class="pt-0"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Procurar na tabela"
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                :search="search"
              />
            </v-col>
          </v-row>      
        </v-form>
      </v-col>
    </v-row>  
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      valid: false,
      search: '',
      headers: [
        {text:'Transação', value:'CE240_CD_TRANS'},
        {text:'Formas de pagamento', value:'CE240_TP_PAGAME'},        
        // {text:'excluir', value:'excluir'},
        
      ],
      items: [
        {CE240_CD_TRANS: 'VENDA SAASPDV DINHEIRO', CE240_TP_PAGAME: 'Dinheiro'},
        {CE240_CD_TRANS: 'VENDA SAASPDV PIX', CE240_TP_PAGAME: 'PIX'},
        {CE240_CD_TRANS: 'VENDA SAASPDV CREDIÁRIO', CE240_TP_PAGAME: 'Crediário próprio'},
        {CE240_CD_TRANS: 'VENDA SAASPDV BOLETO', CE240_TP_PAGAME: 'Boleto'},
        {CE240_CD_TRANS: 'VENDA SAASPDV CHEQUE', CE240_TP_PAGAME: 'Cheque'},
        {CE240_CD_TRANS: 'VENDA SAASPDV DEPÓSITO', CE240_TP_PAGAME: 'Depósito'},
        {CE240_CD_TRANS: 'VENDA SAASPDV VALE FUNCIONÁDIO', CE240_TP_PAGAME: 'Vale Funcionário'},
        {CE240_CD_TRANS: 'VENDA SAASPDV CONVÊNIO', CE240_TP_PAGAME: 'Convênio'},
        {CE240_CD_TRANS: 'VENDA SAASPDV COMISSÃO', CE240_TP_PAGAME: 'Comissão'},
        
      ],

    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>